import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Contact} from '../../services/ContactsStore';
import {Grid} from '@material-ui/core/';
import {observer} from 'mobx-react';
// import Newsletter from '../newsletter/newsletter';
import $ from '../../services/TranslationsStore';

import styles from './contact.module.scss';

class ContactForm extends Component {

    componentWillMount() {
        this.contact = new Contact();
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : (target.type === 'date') ? new Date(target.value) : target.value;
        const name = target.name;

        this.contact.set({
            [name]: value
        });
    }

    handleSubmit = () => {
        this.contact.save();
    }

    renderForm() {
        return (
            <Grid container direction="row" justify="center" className={styles.ContactFormWrapper}>
              <Grid container direction="column" className={styles.ContactForm}>
                <h2>{$('Contact Us')}</h2>
                <TextField
                  name="name"
                  label={$('name')}
                  required
                  InputProps={{
                      disableUnderline: true
                  }}
                  margin="normal"
                  variant="filled"
                  error={this.contact.errors.get('name') !== undefined}
                  helperText={this.contact.errors.get('name') && $('This is a required field.')}
                  onChange={this.handleInputChange}
                  style={{
                      backgroundColor: 'white'
                  }}
                />
                <TextField
                  name="email"
                  label="email"
                  margin="normal"
                  required
                  InputProps={{
                      disableUnderline: true
                  }}
                  variant="filled"
                  error={this.contact.errors.get('email') !== undefined}
                  helperText={this.contact.errors.get('email') && $('Please enter a valid email address.')}
                  onChange={this.handleInputChange}
                  style={{
                      backgroundColor: 'white'
                  }}
                />
                <TextField
                  name="message"
                  label={$('message')}
                  margin="normal"
                  InputProps={{
                      disableUnderline: true
                  }}
                  required
                  multiline
                  variant="filled"
                  error={this.contact.errors.get('message') !== undefined}
                  helperText={this.contact.errors.get('message') && $('This is a required field.')}
                  onChange={this.handleInputChange}
                  rows={3}
                  style={{
                      backgroundColor: 'white'
                  }}
                />
                <Button
                  variant="contained"
                  color="default"
                  style={{
                      backgroundColor: 'white'
                  }}
                  disabled={this.contact.request != null}
                  onClick={this.handleSubmit}>
                  {$('submit')}
                </Button>
              </Grid>
            </Grid>
        );
    }

    renderFeedback() {
        return (
            <Grid container direction="row" justify="center" className={styles.ContactFormWrapper}>
              <Grid container direction="column" className={styles.ContactForm}>
                <h2>&nbsp;</h2>
                <Card>
                  <CardContent>
                    {$('Thank for you contact. We will reply soon.')}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
        );
    }

    render() {
        const element = !this.contact.get('id') ? this.renderForm() : this.renderFeedback();
        return(
            <Grid container direction="row" justify="center" className={styles.Contact}>
              {element}
            </Grid>
        );
    }
}

export default observer(ContactForm);
