import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import $ from '../../services/TranslationsStore';
import styles from './process.module.scss';

const items = [
    {
        id: 1,
        title: $('Planning'),
        points: [
            $('Clearly define the problem to be solved'),
            $('Map and document the processes, flows and systems involved'),
            $('Define a plan and a clear vision of where you want to reach'),
            $('Determine goals to define success without ambiguity')
        ]
    },
    {
        id: 2,
        title: $('Implementation'),
        points: [
            $('Focus on priorities'),
            $('Develop phased and robust'),
            $('Follow the plan with flexibility'),
            $('Test with real users'),
        ]
    },
    {
        id: 3,
        title: $('Monitoring'),
        points: [
            $('Monitor system performance'),
            $('Continuously introduce improvements'),
            $('Maintain open communication with users'),
            $('Ensure system security'),
        ]
    },
];

const ProcessStep = props => (
    <Grid container direction="row" justify="center" alignItems="center" className={styles.Step}>
      <Grid item xs={12} sm={6} className={styles.Title}>
        {props.step.title}
      </Grid>
      <Grid item xs={12} sm={6} className={styles.Points}>
        {props.step.points.map(point => <p key={Math.random()}> <span> > </span> {point}</p>)}
      </Grid>
    </Grid>
);

class Process extends Component {
    render() {
        return(
            <Grid container direction="row" justify="center" alignItems="center" className={styles.Process}>
              <Grid item xs={12} sm={8} className={styles.Container}>
                {items.map(item => <ProcessStep key={item.id} step={item}/>)}
              </Grid>
            </Grid>
        );
    }
}

export default Process;
