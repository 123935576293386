import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import Contact from '../contact/contact';
import Social from '../social/social';
import CompanyInfo from '../companyInfo/companyInfo';
import Location from '../location/location';
import withWidth from '@material-ui/core/withWidth';

import styles from './footer.module.scss';

class Footer extends Component {

    renderMobileFooter = () => {
        return (
            <Grid container direction="column" className={styles.Footer}>
              <Contact/>
              <Location/>
              <CompanyInfo/>
              <Social />
            </Grid>
        );
    }

    renderFooter = () => (
        <Grid container direction="row" justify="center" className={styles.Footer}>
          <Grid item xs={12} sm={10} lg={8}>
            <Grid container direction="row" justify="space-between">
              <Grid item xs={6}>
                <Location/>
                <CompanyInfo/>
              </Grid>
              <Grid item xs={6}>
                <Contact/>
              </Grid>
            </Grid>
          </Grid>
          <Social/>
        </Grid>

    )

    render() {
        const component = this.props.width === 'xs'? this.renderMobileFooter() : this.renderFooter();
        return component;
    }
}

export default withWidth()(Footer);
