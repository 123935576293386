import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import $ from '../../services/TranslationsStore';
import ProcessIcon from '../ProcessIcon/ProcessIcon';
import CodeIcon from '../CodeIcon/CodeIcon';
import TrainingIcon from '../TrainingIcon/TrainingIcon';

import styles from './services.module.scss';

const Service = (props) => (
    <Grid item xs={12} sm={4} className={styles.Service}>
      <p className={styles.Icon}>{props.service.icon()}</p>
      <p className={styles.Title}>{props.service.title}</p>
      <p className={styles.Description}>{props.service.description}</p>
    </Grid>
);

class Services extends Component {

    servicesList = [
        {
            id: 1,
            icon: ProcessIcon,
            title: $('Process Mapping'),
            description: $('Identify key business processes and determine which ones can bring greater gains. Document the processes together with the team to have a solid starting point.')
        },
        {
            id: 2,
            icon: CodeIcon,
            title: $('Software Development'),
            description: $('Software development tailored to the needs, budget and time frame, our focus is the features that help the business reach the goals without ever neglecting the user experience.')
        },
        {
            id: 3,
            icon: TrainingIcon,
            title: $('Training'),
            description: $('Training of the teams involved in the new systems and processes to ensure that change is effective and that mechanisms for continuous improvement are created.')
        },
    ];

    render() {
        return(
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={10} lg={10}>
                <Grid container direction="row" justify="flex-start" className={styles.Services}>
                  {this.servicesList.map(service => <Service key={service.id} service={service} />)}
                </Grid>
              </Grid>
            </Grid>
        );
    }
}


export default Services;
