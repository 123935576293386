import React, {Component} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import {observer} from 'mobx-react';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import {Contents as ContentsCollection} from '../../services/ContentsStore.js';
import 'moment/locale/pt';
import styles from './blogHome.module.scss';

export const Leading = () => {
    return <div style={{height: '125px'}}/>;
};

const BlogContentPreview = ({content}) => {

    const url = 'url(' + content.coverFullPath + ')';

    return (
        <Grid container className={styles.BlogContentPreview}>

          <Grid item xs={12} sm={4} className={styles.Image} style={{backgroundImage: url}}>
            <Link to={'/blog/' + content.get('slug')}>
            </Link>
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className={styles.ContentWrapper}>
              <Link to={'/blog/' + content.get('slug')} style={{textDecoration: "none"}}>
                <h4>{content.get('name')}</h4>
              </Link>

              <p className={styles.Tag}>
                {content.get('tag')}
              </p>

              <Grid container direction="column" justify="space-between" className={styles.X}>
                <p>
                  {content.get('promo')}
                </p>
                <Grid container justify="space-between">
                  <span className={styles.Date}>

                    <Moment locale="pt" format="DD MMMM YYYY">{content.get('published_date')}</Moment>
                  </span>
                  <Link to={'/blog/' + content.get('slug')} style={{textDecoration: "none"}}>
                    <span className={styles.ReadMore}>+ ler mais</span>
                  </Link>
                </Grid>

              </Grid>

            </div>
          </Grid>
        </Grid>
    );
};

class BlogHome extends Component {

    constructor() {
        super();
        this.contents = new ContentsCollection();
    }

    componentDidMount() {
        this.contents.fetch({ordering: '-published_date'});
    }

    render() {
        if(this.contents.models.length === 0) {
            return <CircularProgress color="primary" />;
        }

        const contents = this.contents.models;

        return (
            <div style={{backgroundColor: "#f8f8f8"}}>
              <Menu background='dark' external />
              <div className={styles.Hero}></div>
              <Grid container direction="column" alignItems="center" className={styles.BlogHome}>
                {contents.map(content => (
                    <BlogContentPreview key={content.id} content={content}/>
                ))}
              </Grid>
              <div style={{height: '75px'}}/>
              <Footer/>
            </div>
        );
    }
}

export default observer(BlogHome);
