import React, {Component} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import {observer} from 'mobx-react';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Content} from '../../services/ContentsStore.js';
import {ContentRead} from '../../services/ContentReadsStore.js';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";
import styles from './blogContent.module.scss';
import {useQuery} from '@apollo/react-hooks';
import {CONTENT} from '../../services/Queries';
import {mediaUrl, isAuthenticated} from '../../services/Utils';
import Loading from '../Loading/Loading';

const BlogContent = (props) => {

    const {data, error, loading} = useQuery(CONTENT, {variables: {slug: props.slug}});

    if(!data) {
        return <Loading/>;
    }

    const url = 'url(' + mediaUrl(data.content.cover) + ')';

    return (
            <div className={styles.Blog}>
              <Helmet>
                <title>{data.content.name}</title>
                <meta name="description" content={data.content.text} />
                <link rel="canonical" href={"https:www.solo.com.pt/blog/" + data.content.slug + "/"} />
              </Helmet>
              <Menu background='dark' external />
              <Grid container direction="column" justify="center" alignItems="center">

                <div className={styles.Cover} style={{backgroundImage: url}}>
                </div>

                <Grid className={styles.Cover} item xs={10} sm={8} lg={6} style={{backgroundImage: url}}/>

                <Grid item xs={10} sm={8} lg={6}>
                  <h1 className={styles.Title}>{data.content.name}</h1>
                  {isAuthenticated() && <div className={styles.Warning}>
                    Sessão Iniciada. Contagem de leituras desativada.
                   </div>}
                  <Grid container direction="row" alignItems="center">
                    <img className={styles.Avatar} src={mediaUrl(data.content.author.avatar)} alt=''/>
                    <div style={{paddingLeft: '15px'}}>
                      <p className={styles.Author}>
                        <a href={data.content.author.linkedin} target="_blank"  rel="noopener noreferrer">
                           {data.content.author.name}
                        </a>
                      </p>
                      <Moment className={styles.PublishedDate}>
                        {data.content.published_date}
                      </Moment>
                    </div>

                  </Grid>
                  <div className={styles.ReadTime}>
                    Tempo leitura {data.content.read_time} minutos.
                  </div>

                  <div className={styles.Text}>
                    {ReactHtmlParser(data.content.text)}
                  </div>
                </Grid>
               </Grid>
              <div style={{height: '75px'}}/>
              <Footer/>
            </div>

    );
};

export default BlogContent;
