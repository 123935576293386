import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import $ from '../../services/TranslationsStore';
import styles from './hero.module.scss';

class Hero extends Component {

    handleClick = () => {
        this.props.x('contacts');
        window.dataLayer.push({event: 'calltoaction'});
    }

    render(){
        return(
            <Grid container direction="row" className={styles.Hero}>
              <div className={styles.CallToAction}>
                <p>{$('on Solo')}</p>
                <p>{$('we develop')}</p>
                <p>{$('digital solutions to')}</p>
                <p>{$('simplify processes and workflows.')}</p>
                <Button variant="contained" color="primary" onClick={this.handleClick}>
                  {$('Contact Us')}
                </Button>
              </div>
            </Grid>
        );
    }
}

export default Hero;
