import React from 'react';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';

import styles from './social.module.scss';

const SocialLink = props => {
    const classes = classNames(props.icon, styles.Icon);
    return (
        <a href={props.link} className={classes} rel="noopener noreferrer" target="_blank">
          &nbsp;
        </a>
    );
};

const Social = props => {
    const networks = [
        {
            id: 1,
            link: 'https://www.linkedin.com/company/18063180/',
            icon: 'icon-linkedin'
        },
        {
            id: 2,
            link: 'https://www.facebook.com/solo.com.pt/',
            icon: 'icon-facebook'
        },
        {
            id: 3,
            link: 'https://github.com/orgs/solocompt/',
            icon: 'icon-github'
        }
    ];
    return (
        <Grid container direction="row" justify="center" className={styles.Social}>
          {networks.map(network => <SocialLink key={network.id} link={network.link} icon={network.icon}/>)}
        </Grid>
    );
};

export default Social;
