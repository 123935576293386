import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProcessIcon = props => {
    const path = "M11.127 3.842c-0.666 0.013-1.201 0.556-1.201 1.224s0.535 1.211 1.2 1.224l0.001 0h2.447c0.666-0.013 1.201-0.556 1.201-1.224s-0.535-1.211-1.2-1.224l-0.001-0h-2.447zM3.783 4.656c-0.675 0.001-1.222 0.549-1.223 1.225v3.264h0.814v-3.264c0-0.225 0.183-0.408 0.408-0.408h5.346c-0.026-0.122-0.041-0.263-0.041-0.407 0-0 0-0.001 0-0.001v0c0-0 0-0.001 0-0.001 0-0.144 0.015-0.285 0.043-0.42l-0.002 0.013h-5.346zM12.352 9.227l-2.937 1.957 2.937 1.957 2.936-1.957-2.936-1.957zM1.246 9.961l-0.611 2.447h4.055l0.611-2.447h-4.055zM19.693 9.961v2.447h3.672v-2.447h-3.672zM5.937 10.775l-0.203 0.816h2.822l-0.104-0.068c-0.111-0.074-0.182-0.199-0.182-0.34s0.072-0.266 0.181-0.339l0.001-0.001 0.104-0.068h-2.619zM16.146 10.775l0.102 0.068c0.111 0.074 0.182 0.199 0.182 0.34s-0.072 0.266-0.181 0.339l-0.001 0.001-0.102 0.068h2.73v-0.816h-2.73zM2.561 13.225v1.631c0 0.675 0.547 1.223 1.223 1.225h7.752c0.675-0.001 1.222-0.549 1.223-1.225v-1.006l-0.182 0.121c-0.063 0.043-0.142 0.068-0.226 0.068s-0.162-0.025-0.227-0.069l0.001 0.001-0.182-0.121v1.006c-0 0.225-0.183 0.408-0.408 0.408h-7.752c-0.225-0-0.408-0.183-0.408-0.408v0-1.631h-0.814zM21.326 13.225v3.648c0.121 0.041 0.222 0.084 0.319 0.133l-0.014-0.006c0.148 0.076 0.319 0.132 0.5 0.159l0.009 0.001v-3.936h-0.814zM20.443 17.553c-0.253 0.010-0.489 0.074-0.699 0.18l0.010-0.004-0.469 0.234v2.195h4.080v-2.332l-0.373 0.092c-0.182 0.047-0.391 0.075-0.607 0.075-0.408 0-0.793-0.098-1.134-0.271l0.014 0.007c-0.22-0.112-0.48-0.177-0.755-0.177-0.024 0-0.047 0-0.071 0.001l0.003-0zM10.719 17.711c-0.666 0.013-1.201 0.556-1.201 1.224s0.535 1.211 1.2 1.224l0.001 0h2.447c0.666-0.013 1.201-0.556 1.201-1.224s-0.535-1.211-1.2-1.224l-0.001-0h-2.447zM15.166 18.527c0.026 0.123 0.041 0.264 0.041 0.408s-0.015 0.285-0.043 0.422l0.002-0.013h3.305v-0.816h-3.305z";
    return (
        <SvgIcon {...props}>
          <path d={path} />
        </SvgIcon>
    );
};

export default ProcessIcon;
