import React, {Component} from 'react';
import Menu from '../menu/menu';
import Hero from '../hero/hero';
import Mission from '../mission/mission';
import Services from '../services/services';
import Process from '../process/process';
import Rationale from '../rationale/rationale';
import Projects from '../projects/projects';
import Footer from '../footer/footer';
import Spread from '../spread/spread';
import scrollToComponent from 'react-scroll-to-component';


class Home extends Component {

    sections = {}

    scrollTo = section => {
        const scrollParams = {offset: -69, align: 'top', duration: 500, ease:'inCirc'};
        scrollToComponent(this.sections[section], scrollParams);
    }

    setReference = (ref, section) => {
        this.sections[section] = ref;
    }

    render() {
        return (
            <>
	      <Menu x={this.scrollTo}/>
              <div style={{height: '75px'}}/>
              <Hero x={this.scrollTo} ref={ref => this.setReference(ref, 'about')}/>
	      <Mission text={0}/>
	      <Spread>
	        <Services ref={ref => this.setReference(ref, 'services')}/>
	        <Process ref={ref => this.setReference(ref, 'process')}/>
	      </Spread>
	      <Mission text={1}/>
	      <Rationale/>
	      <Projects ref={ref => this.setReference(ref, 'projects')}/>
	      <Footer ref={ref => this.setReference(ref, 'contacts')}/>
            </>
        );
    }
}

export default Home;
