import React, {useState} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import styles from './ScheduleView.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import {useMutation} from '@apollo/react-hooks';
import {CREATE_CONTACT} from '../../services/Queries';
import Grid from '@material-ui/core/Grid';

const ThankYou = () => {
    return (
        <h3 data-cy="thankyou">
          Obrigado pelo envio da sua mensagen. Entraremos em contacto muito em breve.
        </h3>
    );
};

const ContactForm = ({submit}) => {

    const subscribeSchema = Yup.object().shape({
        name: Yup.string()
            .required('nome é um campo obrigatório'),
        email: Yup.string()
            .email('email tem que ser válido')
            .required('email é um campo obrigatório'),
        message: Yup.string()
            .required('mensagem é um campo obrigatório')
    });

    return (
        <Formik
          initialValues={{
              name: '',
              email: ''
          }}
          validationSchema={subscribeSchema}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
              submit(values).then((response, loading, error) => {
                  setSubmitting(false);
              });
          }}
        >
          {({isValid, errors, touched, dirty, submitForm, isSubmitting}) => (
              <Form>
                <Grid container direction="column">
                  <Field
                    component={TextField}
                    name="name"
                    type="text"
                    label="nome"
                    autoFocus
                    required
                    data-cy="name"
                    className={styles.FormField}
                  />
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="email"
                    data-cy="email"
                    required
                    className={styles.FormField}
                  />
                  <Field
                    component={TextField}
                    name="message"
                    type="text"
                    label="mensagem"
                    data-cy="message"
                    required
                    multiline
                    className={styles.FormField}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValid | !dirty}
                    onClick={submitForm}
                    data-cy="submit"
                    className={styles.FormField}
                  >
                    Submeter
                  </Button>
                </Grid>
              </Form>
          )}
        </Formik>
    );
};

const ContactView = () => {

    const [submitted, setSubmitted] = useState(false);
    const [createContact] = useMutation(CREATE_CONTACT);

    const submit = values => {
        return createContact({variables: values}).then(() => setSubmitted(true));
    };

    return (
        <>
          <Menu background='dark' external />
          <div style={{height: '75px'}}/>
          <Grid container justify="center" className={styles.ScheduleView}>
            <Grid container direction="column" item xs={10} lg={5}>
              <h2>Agende uma reunião</h2>
              <h3>
                Interessado em saber mais sobre o nosso serviço, de descobrir os como podemos
                ajudá-lo na <b>transformação digital</b> da sua empresa? Agende uma reunião online ou presencial sem compromisso.
              </h3>
              {submitted ? <ThankYou/> : <ContactForm submit={submit}/>}
            </Grid>
          </Grid>
          <Footer/>
        </>
    );
};

export default ContactView;
