import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TrainingIcon = props => {
    const path = "M11.816 3.414c-1.384 0-2.538 0.923-2.9 2.176-0.428-0.461-1.054-0.758-1.746-0.758-1.318 0-2.406 1.088-2.406 2.406s1.088 2.406 2.406 2.406c0.989 0 1.845-0.594 2.207-1.451 0.56 0.758 1.418 1.219 2.439 1.219 0.989 0 1.879-0.494 2.439-1.219 0.363 0.857 1.218 1.451 2.207 1.451 1.318 0 2.406-1.088 2.406-2.406s-1.088-2.406-2.406-2.406c-0.692 0-1.318 0.296-1.746 0.758-0.363-1.252-1.516-2.176-2.9-2.176zM10.004 10.369c-0.857 0-2.701 0.428-3.262 1.021-0.066 0.099-1.055-0.66-1.055-0.66-0.56 0-1.846 0.264-2.209 0.627-0.461 0.494-0.659 1.581-0.494 2.24l0.955 3.527c0.066 0.264 0.297 0.494 0.561 0.494h2.373l0.627 2.273c0.099 0.395 0.46 0.693 0.855 0.693h7.252c0.395 0 0.757-0.298 0.855-0.693l0.594-2.273h2.439c0.264 0 0.495-0.23 0.561-0.494l0.955-3.494c0.165-0.692 0-1.779-0.494-2.273-0.363-0.363-1.616-0.594-2.176-0.594l-0.824 1.088c-0.264-0.165-0.23-0.396-0.361-0.527-0.56-0.56-2.407-0.955-3.264-0.955h-3.889z";
    return (
        <SvgIcon {...props}>
          <path d={path} />
        </SvgIcon>
    );
};

export default TrainingIcon;
