import React, {useState} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import styles from './Login.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import {useMutation} from '@apollo/react-hooks';
import {REMOVE_SUBSCRIPTION, LOGIN} from '../../services/Queries';
import {LOGIN_USER} from '../../services/Local';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import { useUserRedirection } from '../../services/Hooks';

const Login = () => {

    useUserRedirection();

    const [submitted, setSubmitted] = useState(false);
    const [login] = useMutation(LOGIN);
    const [loginUser] = useMutation(LOGIN_USER);

    const schema = Yup.object().shape({
        email: Yup.string()
            .email('email tem que ser válido')
            .required('email é um campo obrigatório'),
        password: Yup.string()
            .required('password é um campo obrigatório'),
    });

    return (
        <>
          <Menu background='dark' external />
          <div style={{height: '75px'}}/>
          <Grid container justify="center" className={styles.Login}>
            <Grid container direction="column" item xs={10} lg={5}>
              <h2>Login</h2>
              <Formik
                initialValues={{
                    name: '',
                    email: ''
                }}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting }) => {
                    login({ variables: values }).then((response) => {
                        setSubmitting(false);
                        if (response?.data?.login?.token) {
                            loginUser({ variables: response?.data?.login });
                        }
                    });
                }
                         }
              >
                {({isValid, errors, touched, dirty, submitForm, isSubmitting}) => (
                    <Form>
                      <Grid container direction="column">
                        <Field
                          component={TextField}
                          name="email"
                          type="email"
                          label="email"
                          data-cy="email"
                          required
                          autoFocus
                          className={styles.FormField}
                        />
                        <Field
                          component={TextField}
                          name="password"
                          type="password"
                          label="password"
                          data-cy="password"
                          required
                          className={styles.FormField}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting || !isValid | !dirty}
                          onClick={submitForm}
                          data-cy="submit"
                          className={styles.FormField}
                        >
                          Submeter
                        </Button>
                      </Grid>
                    </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </>
    );
};

export default Login;
