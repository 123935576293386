import React from 'react';
import styles from './spread.module.scss';

const Spread = props => {
    return (
        <div className={styles.Spread}>
          {props.children}
        </div>
    );
};

export default Spread;
