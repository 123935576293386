import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';
import styles from './projects.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from '../../services/TranslationsStore';
import {observer} from 'mobx-react';
import {Button} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import {Projects as ProjectsCollection} from '../../services/ProjectsStore.js';

const Arrow = props => {
    const classes = classNames(styles.Arrow, 'icon-angle-' + props.direction);
    return (
        <button className={classes} onClick={props.onClick} disabled={props.disabled}/>
    );
};

const Project = props => (
    <Grid item className={styles.Project}>
      <p className={styles.Title}>{$('Projects')}</p>
      <p className={styles.Client}>{props.project.get('client')}</p>
      <Grid container direction="row" justify="space-around" alignItems="center">
        <Grid item xs={12} sm={6}>
          <p className={styles.Description}>{props.project.get('description')}</p>
        </Grid>
        <Grid item sm={2}>
          <p className={styles.Logo}>
            <img src={props.project.logoFullPath} alt=''/>
          </p>
        </Grid>
      </Grid>
    </Grid>
);


class Projects extends Component {

    state = {
        id: 0,
    }

    filters = {
        page_size: 25
    }

    componentWillMount() {
        this.projects = new ProjectsCollection();
    }

    componentDidMount() {
        this.projects.fetch(this.filters);
    }

    next = () => {
        this.setState({id: this.state.id + 1});
    }

    render() {
        if(this.projects.models.length === 0) {
            return <CircularProgress color="primary" />;
        }
        return(
            <>
              <Grid container direction="row" justify="center" alignItems="center" className={styles.Projects}>
                <Grid item xs={12} sm={10} lg={8} style={{backgroundColor: 'transparent'}}>
                  <Grid container direction="row" justify="center">
                    <Arrow direction='left' disabled={this.state.id === 0} onClick={event => this.setState({id: this.state.id - 1})}/>
                    <Project project={this.projects.models[this.state.id]} />
                    <Arrow direction='right' disabled={this.state.id === this.projects.models.length - 1} onClick={event => this.setState({id: this.state.id + 1})} />
                  </Grid>
                </Grid>
              </Grid>
              <Hidden smUp>
                <div style={{textAlign: 'center', backgroundColor: '#f8f8f8', paddingBottom: '25px'}}>
                  <Button className={styles.Next} onClick={this.next}>
                    {$('Next Project')}
                  </Button>
                </div>
              </Hidden>
            </>
        );
    }
}

export default observer(Projects);
