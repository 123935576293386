import gql from 'graphql-tag';

// queries

export const CONTENT = gql`
query content($slug: String!) {
    content(slug: $slug) {
        id
        name
        text
        cover
        read_time
        published_date
        author {
            id
            name
            avatar
            linkedin
        }
    }
}
`;


export const GET_STATUS = gql`
query get_status {
    get_status
}
`;




// mutations

export const CREATE_CONTACT = gql`
mutation create_contact($name: String!, $email: String!, $message: String!) {
    create_contact(name: $name, email: $email, message: $message) {
        contact {
            id
            name
            email
            message
        }
        errors {
            field
            message
        }
    }
}
`;


export const CREATE_SUBSCRIPTION = gql`
mutation create_subscription($name: String, $email: String!) {
    create_subscription(name: $name, email: $email) {
        subscription {
            id
            name
            email
            is_subscribed
        }
        errors {
            field
            message
        }
    }
}
`;

export const REMOVE_SUBSCRIPTION = gql`
mutation remove_subscription($email: String!) {
    remove_subscription(email: $email) {
        errors {
            field
            message
        }
    }
}
`

export const LOGIN = gql`
mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
        token
        errors {
            field
            message
        }
    }
}
`;
