import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {Button} from '@material-ui/core';
import classNames from 'classnames';
import $ from '../../services/TranslationsStore';
import styles from './terms.module.scss';

const message = $('Solo uses cookies to improve your experience using our website. We also collect personal information about you as described in the link ahead. By using our services you agree to the use of cookies and to the collection of your personal information.');

class Terms extends Component {

    state = {
        show: true
    }

    componentWillMount() {
        this.storage = window.localStorage;
        this.setState({show: !this.storage.getItem('agree')});
    }

    handleDismiss = () => {
        this.storage.setItem('agree', true);
        this.setState({show: false});
    }

    renderBanner() {
        const dismiss = classNames('icon-cancel', styles.GdprBannerDismiss);
        return (
            <div className={styles.GdprBanner}>
              <Grid container direction="row" justify="center" alignItems="flex-start" className={styles.GdprBannerContainer}>
                <div className={styles.GdprBannerText}>{message} <a href="#terms" target="_blank">{$('Learn more about this.')}</a></div>
                {/* <Button variant="contained" color="primary" onClick={this.handleDismiss}>{$('I agree')}</Button> */}
                <Button disableRipple className={dismiss} onClick={this.handleDismiss}>&nbsp;</Button>
              </Grid>
            </div>
        );
    }

    render() {
        const element = this.state.show ? this.renderBanner() : null;
        return element;
    }
}

export default Terms;
