import './platformicons.css';
import React, { Component } from 'react';
import Terms from './components/terms/terms';
import Home from './components/home/home';
import Blog from './components/blog/blog';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import theme from './theme.js';
// eslint-disable-next-line no-unused-vars
import moment from './moment.config.js';
import Status from './components/Status/Status';
import { ApolloProvider } from '@apollo/react-hooks';
import client from "./services/Client";
import NewsletterView from './components/NewsletterView/NewsletterView';
import ScheduleView from './components/ScheduleView/ScheduleView';
import NewsletterUnsubscribeView from './components/NewsletterUnsubscribeView/NewsletterUnsubscribeView';
import Login from './components/Login/Login';


class App extends Component {
    render() {
        return (
		<ApolloProvider client={client}>
		<Router>
                <MuiThemeProvider theme={theme}>
                <Terms/>
                <Route exact path="/" component={Home}/>
                <Route exact path="/blog/" component={Blog}/>
                <Route exact path="/blog/:slug" component={Blog}/>
                <Route exact path="/status" component={Status}/>
                <Route exact path="/newsletter" component={NewsletterView}/>
		<Route exact path="/remover" component={NewsletterUnsubscribeView}/>
                <Route exact path="/agendar" component={ScheduleView}/>
		<Route exact path="/login" component={Login}/>
                </MuiThemeProvider>
		</Router>
		</ApolloProvider>
        );
    }
}

export default App;
