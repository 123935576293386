import apisauce from 'apisauce';
import {LocalConfigs} from "../LocalConfigs";

const create = () => {
    const api = apisauce.create({
        baseURL: LocalConfigs.API_URL,
        headers: {},
        timeout: 15 * 1000
    });


    api.addResponseTransform(response => {
        if (response.status === 429) {
            console.log('too many requests');
        }
    });

    return api;
};

export default create();
