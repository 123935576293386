import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
	    main: '#012b3c',
	    light: 'red',
	    dark: '#12485e',
	    contrastText: '#fdfdfd',
        }
    },
});

export default theme;
