import React, {useState} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import styles from './NewsletterUnsubscribeView.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import {useMutation} from '@apollo/react-hooks';
import {REMOVE_SUBSCRIPTION} from '../../services/Queries';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';

const ThankYou = () => {
    return (
        <h3 data-cy="thankyou">
          O seu email foi removido. Obrigado!
        </h3>
    );
};

const NewsletterForm = ({submit}) => {

    const subscribeSchema = Yup.object().shape({
        email: Yup.string()
            .email('email tem que ser válido')
            .required('email é um campo obrigatório'),
    });

    return (
        <Formik
          initialValues={{
              name: '',
              email: ''
          }}
          validationSchema={subscribeSchema}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
              submit(values).then((response, loading, error) => {
                  setSubmitting(false);
              });
          }}
        >
          {({isValid, errors, touched, dirty, submitForm, isSubmitting}) => (
              <Form>
                <Grid container direction="column">
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="email"
                    data-cy="email"
                    required
                    autoFocus
                    className={styles.FormField}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValid | !dirty}
                    onClick={submitForm}
                    data-cy="submit"
                    className={styles.FormField}
                  >
                    Submeter
                  </Button>
                </Grid>
              </Form>
          )}
        </Formik>
    );
};

const NewsletterView = () => {

    const [submitted, setSubmitted] = useState(false);
    const [removeSubscription] = useMutation(REMOVE_SUBSCRIPTION);

    const submit = values => {
        return removeSubscription({variables: values}).then(() => setSubmitted(true));
    };

    return (
        <>
          <Menu background='dark' external />
          <div style={{height: '75px'}}/>
          <Grid container justify="center" className={styles.NewsletterView}>
            <Grid container direction="column" item xs={10} lg={5}>
              <h2>Cancelar Subscrição</h2>
              <h3>
                Temos pena que não queira continuar a receber as nossas novidades e promoções na sua caixa de correio. Para remover o seu email preencha por favor o campo em baixo.
              </h3>
              {submitted ? <ThankYou/> : <NewsletterForm submit={submit}/>}
              <p className={styles.Disclaimer}>
                <small>
        No futuro pode sempre voltar a receber os nossos emails, basta inscrever-se <Link to="newsletter" style={{fontWeight: "bold", color: "#000000"}}>aqui</Link>.<br/>
                </small>

              </p>
            </Grid>
          </Grid>
          <Footer/>
        </>
    );
};

export default NewsletterView;
