import {Model} from './base/Model';
import Collection from './base/Collection';
import {decorate, computed, action} from 'mobx';
import Api from './base/Api';
import {User} from './UsersStore';

export class Content extends Model {
    urlRoot() {
        return "/contents/";
    }

    get primaryKey() {
        return 'slug';
    }

    get coverFullPath() {
        return Api.getBaseURL() + '/static/media/' + this.get('cover');
    }

    get author() {
	const data = new User({id: this.get('author')})
        data.fetch()
        return data
    }
}

decorate(Content, {
    coverFullPath: computed,
    author: computed,
    createContentRead: action
})


export class Contents extends Collection {
    urlRoot() {
        return "/contents/";
    }

    model() {
        return Content
    }
}
