import React from 'react';
import $ from '../../services/TranslationsStore';
import {Grid} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import styles from './companyInfo.module.scss';

const CompanyInfoMobile = () => (
    <>
      <p>Solo, Lda</p>
      <p><b>{$('address')}</b></p>
      <p>Rua Dr. Paulo Pombo, 82<br/> 4250-363 Porto</p>
      <p><b>email</b></p>
      <p><a href="mailto:solo@solo.com.pt">solo@solo.com.pt</a></p>
      <p><b>{$('phone')}</b></p>
      <p>+351 224 094 796</p>
    </>
);

const CompanyInfoDesktop = () => (
    <>
      <p>Solo, Lda</p>
      <p>Rua Dr. Paulo Pombo, 82</p>
      <p>4250-363 Porto</p>
      <p><b>email</b>: <a href="mailto:solo@solo.com.pt">solo@solo.com.pt</a></p>
      <p><b>{$('phone')}: </b>+351 224 094 796</p>
    </>
);

const CompanyInfo = props => {
    const component = props.width === 'xs'? CompanyInfoMobile : CompanyInfoDesktop;
    return (
    <Grid container direction="row" justify="center">
      <Grid container direction="column" className={styles.CompanyInfo}>
        {component()}
      </Grid>
    </Grid>
    );
};

export default withWidth()(CompanyInfo);
