import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CodeIcon = props => {
    const path = "M13.111 3.324l-4.488 16.754 2.223 0.598 4.488-16.754zM17.77 6.23l-1.667 1.667 4.146 4.146-4.146 4.146 1.667 1.667 4.103-4.146 1.667-1.667-1.667-1.667zM6.23 6.23l-5.77 5.77 1.667 1.667 4.146 4.103 1.667-1.667-4.146-4.146 4.146-4.146z";
    return (
        <SvgIcon {...props}>
          <path d={path} />
        </SvgIcon>
    );
};

export default CodeIcon;
