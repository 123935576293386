import { Config } from '../services/Config';

export const mediaUrl = (rel) => (
    Config.MEDIA_URL + rel
)

export const isAuthenticated = ()  => {
    const token = localStorage.getItem('auth.token')
    return token !== null && token !== undefined
}
