import React from 'react';
import styles from './mission.module.scss';
import {Grid} from '@material-ui/core';
import classNames from 'classnames';
import $ from '../../services/TranslationsStore';

const texts = [
    $('Our mission is to simplify the day-to-day of our customers by developing technological products carefully thought out and executed.'),
    $('We map and document processes, analyze systems on the spot, and work with our customers to identify key issues and discover opportunities.')
];

const Mission = props => {
    let text = !isNaN(props.text) ? texts[props.text] : props.text;
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={styles.Mission}>
          <Grid item xs={12} sm={10} lg={8}>
            <Grid container direction="row" justify="space-between">
              <p className={classNames("icon-left-arrow", styles.Icons)} />
              <p className={styles.Text}>{text}</p>
              <p className={classNames("icon-right-arrow", styles.Icons)} />
            </Grid>
          </Grid>
        </Grid>
    );
};


export default Mission;
