import React from 'react';
import {Grid} from '@material-ui/core';
import $ from '../../services/TranslationsStore';
import classNames from 'classnames';
import styles from './rationale.module.scss';
import defaultImg from '../../assets/images/water.jpg';


const WhyCustom = props => {
    const img = props.image === undefined ? defaultImg : props.image;
    const url = 'url(' + img + ')';
    const classes = props.theme === 'light' ? [styles.WhyCustom, styles.Dark] : [styles.WhyCustom, styles.Light];
    return  (
        <Grid container direction="row" className={classNames(classes)} style={{backgroundImage: url}}>
          <Grid item xs={2}>
          </Grid>
          <Grid item xs={10} sm={2} >
            <p>{$('Why<br/> develop<br/> custom<br/> software<br/> for your<br/> business.')}</p>
            <p>{$('Less errors')}</p>
            <p>{$('Fewer problems')}</p>
            <p>{$('Less manual tasks')}</p>
            <p>{$('Less time spent')}</p>
            <p>{$('More engaged workers')}</p>
            <p>{$('More speed and agility')}</p>
          </Grid>
        </Grid>
    );
};

const Point = props => (
    <Grid item xs={12} sm={6} className={styles.Point}>
      <p>{props.title}</p>
      <p>{props.description}</p>
    </Grid>
);

const WhyUs = props => {
    const points = [
        {
            id: 1,
            title: $('Open Source'),
            description: $('Without licensing costs, you will not be stuck with the vendor.')
        },
        {
            id: 2,
            title: $('Proximity'),
            description: $('We work there, we know the business from the inside.')
        },
        {
            id: 3,
            title: $('Modern Technologies'),
            description: $('Speed, productivity and scalability, oriented towards the future.')
        },
        {
            id: 4,
            title: $('Confidentiality'),
            description: $('From the first day the rule is total confidentiality.')
        },
        {
            id: 5,
            title: $('User Experience'),
            description: $('End User Friendly Business Software.')
        },
        {
            id: 6,
            title: $('Focus, Clarity and Metrics'),
            description: $('Data and metrics provided securely.')
        },
    ];
    return (
        <Grid container direction="row" justify="center">
          <Grid item xs={12} sm={10} lg={8} className={styles.WhyUs}>
            <p className={styles.SectionTitle}>{$('Why Works with Us')}</p>
            <Grid container direction="row" justify="center">
              { points.map(point => <Point key={point.id} {...point} />) }
            </Grid>
          </Grid>
        </Grid>
    );
};

const Rationale = props => (
    <Grid container direction="column" className={styles.Rationale}>
      <WhyCustom {...props}/>
      <WhyUs/>
    </Grid>
);

export default Rationale;
