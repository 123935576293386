import queryString from 'query-string';
import Api from "./base/Api";
import ReactHtmlParser from 'react-html-parser';
import TranslationsData from './TranslationsData';


class Translations {

    translations = TranslationsData

    languages = [
	{code: 'pt', flag: 'br', name: 'Português'},
	{code: 'en', flag: 'us', name: 'English'},
    ]

    constructor() {
        this.loadLang();
    }

    _getBrownserLang(defaultLang = 'en') {
        var lang = navigator.language || navigator.userLanguage;
        if (!lang) {
            return defaultLang;
        }

        try {
            lang = lang.split('-');
            lang = lang[0];

            if (lang === 'pt' || lang === 'en') {
                return lang.toLowerCase();
            }
        } catch (e) {
            return defaultLang;
        }
    }

    _getQueryLang() {
        const values = queryString.parse(window.location.search);
        const supportedLangs = this.languages.map(item => item.code);
        return supportedLangs.indexOf(values.lang) !== -1 ? values.lang : null;
    }

    changeLang(newLang) {
        this.lang = newLang;
        localStorage.setItem('translator-lang', newLang);
        window.location.reload();
    }

    loadLang() {
        this.lang = localStorage.getItem('translator-lang') || this._getQueryLang() ||this._getBrownserLang();
        Api.setHeader('Accept-Language', this.lang);
    }

    getTranslationsTemplate() {
        return JSON.stringify(this.translations);
    }
}

const Translator = new Translations();

const translate = (string) => {
    var translated = null;
    try {
        translated = Translator.translations[string][Translator.lang];
    } catch (e) {
        Translator.translations[string] = {
            pt: null,
            en: null,
        };
        // console.error('Translations template outdated', Translator.getTranslationsTemplate());
    }


    return ReactHtmlParser((translated) ? translated : string);
};

export default translate;
export {Translator}
