import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import logo from '../../assets/images/logo.png';
import classNames from 'classnames';
import $ from '../../services/TranslationsStore';
import LanguageSelector from '../languageSelector/languageSelector';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import {Button} from '@material-ui/core';
import {LocalConfigs} from '../../services/LocalConfigs';

import styles from './menu.module.scss';

class Menu extends Component {


    constructor(props) {
        super(props);
        const defaultSections = ['services', 'process', 'projects', 'blog', 'contacts'];
        this.sections = (props.sections === undefined) ? defaultSections : props.sections;

        const c = [styles.TopBar];

        if(props.background === 'dark') {
            c.push(styles.BackgroundBlue);
        }

        this.state = {
            classes: c,
            active: '',
            open: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = event => {
        if(this.props.background !== 'dark') {

        if(window.scrollY > 100 && this.state.classes.length === 1) {
            this.setState({classes: [styles.TopBar, styles.Bordered]});
        }
        if(window.scrollY < 100 && this.state.classes.length === 2) {
            this.setState({classes: [styles.TopBar]});
        }

        }
    }

    handleRouteChange = (section) => {
        this.props.x(section);
        this.setState({active: section, open: false});
    }

    renderLink = section => {
        let link = '';
        if(this.props.external && section !== 'blog') {
            link = 'https://www.solo.com.pt/#' + section;
        } else if(section === 'blog') {
            link = '/blog';
        }
        else {
            link = '#' + section;
        }
        const classes = classNames('navLink', {[styles.Underlined]: this.state.active === section});
        return (
            <a href={link} key={Math.random()} onClick={() => this.handleRouteChange(section)} className={classes}>
              {$(section)}
            </a>
        );
    }

    toggleDrawer = () => {
        this.setState({open: !this.state.open});
    };

    renderMobile = () => {
        const {sections} = this;
        const classes = classNames(this.state.classes);
        return (
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes}>
              <div className={styles.Logo}>
                <a href={LocalConfigs.APP_URL} onClick={() => this.handleRouteChange('')}>
                  <img src={logo} alt=''/>
                </a>
              </div>
              <Button className="icon-bars" onClick={this.toggleDrawer} style={{color: 'white', fontSize: '20px', marginBottom: '5px'}}>
                &nbsp;
              </Button>
              <Drawer open={this.state.open} anchor="top" className={styles.Menu}>
                <div style={{marginTop: '100px'}}>&nbsp;</div>
                {sections.map(section => this.renderLink(section))}
                <LanguageSelector/>
              </Drawer>
            </Grid>
        );
    }

    renderDesktop = () => {
        const {sections} = this;

        return (
            <Grid container direction="row" className={classNames(this.state.classes)}>
              <Grid item sm={1} />
              <Grid item sm={5} className={styles.Logo}>
                <a href={LocalConfigs.APP_URL} onClick={() => this.handleRouteChange('')}>
                  <img src={logo} alt=''/>
                </a>
              </Grid>
              <Grid item sm={4} className={styles.Menu}>
                <Grid container justify="space-around">
                  {sections.map(section => this.renderLink(section))}
                  <LanguageSelector/>
                </Grid>
              </Grid>
            </Grid>
        );
    }

    render() {
        return (this.props.width === 'xs') ? this.renderMobile() : this.renderDesktop();
    }

}

export default withWidth()(Menu);
