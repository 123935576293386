import React from 'react';
import map from '../../assets/images/map.png';

import styles from './location.module.scss';

const Location = props => (
    <div className={styles.Location}>
      <img src={map} alt=""/>
    </div>
);

export default Location;
