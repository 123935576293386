const TranslationsData = {
    // Menu
    "services": {
	"en": "Services",
	"pt": "Serviços"
    },
    "process": {
	"en": "Process",
        "pt": "Processo"
    },
    "projects": {
	"en": "Projects",
        "pt": "Projetos"
    },
    "blog": {
	"en": "Blog",
        "pt": "Blog"
    },
    "contacts": {
	"en": "Contacts",
        "pt": "Contactos"
    },
    "footwear": {
	"en": "Footwear",
        "pt": "Calçado"
    },
    // Services
    'Process Mapping': {
	"en": "Process Mapping",
	"pt": "Mapeamento Processos",
    },
    'Identify key business processes and determine which ones can bring greater gains. Document the processes together with the team to have a solid starting point.': {
	'en': 'Identify key business processes and discover which ones can bring greater gains. Document these processes together with the respective teams to have a solid starting point.',
	'pt': 'Identificar os principais processos de negócio e determinar os que podem trazer maior retorno. Documentar estes processos juntamente com as equipas para criar uma base sólida.'
    },
    'Software Development': {
	"en": "Software Development",
	"pt": "Desenvolvimento Software",
    },
    'Software development tailored to the needs, budget and time frame, our focus is the features that help the business reach the goals without ever neglecting the user experience.': {
	'en': 'Software development tailored to needs, budget and time frame. Iterative development focused on the features that help achieve business goals.',
	'pt': 'Desenvolvimento de software à medida das necessidades, orçamento e prazo. Desenvolvimento iterativo e focado nas funcionalidades que ajudam a atingir as metas do negócio.'
    },
    'Training': {
	"en": "Training",
	"pt": "Formação"
    },
    'Training of the teams involved in the new systems and processes to ensure that change is effective and that mechanisms for continuous improvement are created.': {
	'en': 'Training of the teams involved in the new systems and processes to ensure that change is effective and that mechanisms for continuous improvement are created.',
	'pt': 'Formação das equipas envolvidas nos novos sistemas e processos para garantir que a mudança é efetiva e que são criados os mecanismos para uma melhoria contínua.'
    },
    // Rationale
    'Why Works with Us': {
	'en': 'Why Works with Us',
	'pt': 'Porque trabalhar connosco.'
    },
    'Open Source': {
	"en": "Open Source",
	"pt": "Open Source"
    },
    'Without licensing costs, you will not be stuck with the vendor.': {
	'en': 'Without licensing costs, you will not be stuck with the vendor.',
	'pt': 'Sem custos com licenças e não fica limitado pelo fornecedor.'
    },
    'Proximity': {
	"en": "Proximity",
	"pt": "Proximidade"
    },
    'We work there, we know the business from the inside.': {
	'en': 'We work locally, we know the business from the inside.',
	'pt': 'Trabalhamos no local, conhecemos os negócios por dentro.'
    },
    'Modern Technologies': {
	'en': 'Modern Technologies',
	'pt': 'Tecnologias Modernas'
    },
    'Speed, productivity and scalability, oriented towards the future.': {
	'en': 'Speed, productivity and scalability, oriented towards the future.',
	'pt': 'Rapidez, produtividade e escalabilidade, orientado para o futuro.'
    },
    'Confidentiality': {
	'en': 'Confidentiality',
	'pt': 'Confidencialidade'
    },
    'From the first day the rule is total confidentiality.': {
	'en': 'Absolute confidentiality from day one.',
	'pt': 'Total confidencialidade desde o primeiro contacto.'
    },
    'User Experience': {
	'en': 'User Experience',
	'pt': 'Experiência do Utilizador'
    },
    'End User Friendly Business Software.': {
	'en': 'User-friendly business software.',
	'pt': 'Software empresarial a pensar no utilizador final.'
    },
    'Focus, Clarity and Metrics': {
	'en': 'Focus, Clarity and Metrics',
	'pt': 'Foco, clareza e Métricas'
    },
    'Data and metrics provided securely.': {
	'en': 'Clear plan and metrics to keep the development focused.',
	'pt': 'Definimos um plano e métricas para garantir o seu cumprimento.'
    },
    // Footer
    'address': {
    	'en': 'address',
    	'pt': 'morada'
    },
    'phone': {
    	'en': 'phone',
    	'pt': 'telefone'
    },
    'name': {
    	'en': 'name',
    	'pt': 'nome'
    },
    'message' : {
    	'en': 'message',
    	'pt': 'mensagem'
    },
    'submit': {
    	'en': 'submit',
    	'pt': 'submeter'
    },
    'Thank for you contact. We will reply soon.': {
	'en': 'Thank for you contact. We will reply soon.',
	'pt': 'Obrigado pelo seu contacto. Responderemos em breve.'
    },
    'This is a required field.': {
	'en': 'This is a required field.',
	'pt': 'Este campo é obrigatório.'
    },
    'Please enter a valid email address.': {
	'en': 'Please enter a valid email address.',
	'pt': 'Introduza um email válido.'
    },
    // Newsletter
    'subscribe': {
    	'en': 'subscribe',
    	'pt': 'inscrever'
    },
    'Thank you for your subscription.': {
    	'en': 'Thank you for your subscription.',
    	'pt': 'Obrigado pelo seu registo.'
    },
    // Projects
    'Projects': {
    	'en': 'Projects',
    	'pt': 'Projetos'
    },
    // Terms
    'Solo uses cookies to improve your experience using our website. We also collect personal information about you as described in the link ahead. By using our services you agree to the use of cookies and to the collection of your personal information.': {
	'en': 'Solo uses cookies to improve your experience using our website. We also collect personal information about you as described in the link ahead. By using our services you agree to the use of cookies and to the collection of your personal information.',
	'pt': 'A Solo usa cookies para melhorar a sua experiência no nosso site. Também recolhemos informação pessoal sobre si como detalhado no link à frente. Ao usar os nossos serviços está a aceitar o uso de cookies e à recolha de informação pessoal.'
    },
    'Learn more about this.': {
	'en': 'Learn more about this.',
	'pt': 'Saber mais.'
    },
    'I agree': {
	'en': 'I agree',
	'pt': 'Concordo'
    },
    // Mission
    'Our mission is to simplify the day-to-day of our customers by developing technological products carefully thought out and executed.': {
    	'en': 'Our mission is to simplify the day-to-day of our customers by developing carefully thought out and executed technological products.',
    	'pt': 'A nossa missão é simplificar o dia-a-dia dos nossos clientes através do desenvolvimento de produtos tecnológicos cuidadosamente pensados e executados.'
    },
    'We map and document processes, analyze systems on the spot, and work with our customers to identify key issues and discover opportunities.': {
    	'en': 'We map and document processes, examine the systems involved and work with our clients to identify key issues and discover opportunities.',
    	'pt': 'Mapeamos e documentámos processos, analisamos os sistemas no local e trabalhamos com os nossos clientes para identificar os principais problemas e descobrir oportunidades.'
    },
    // Hero
    'Contact Us': {
	'en': 'Contact Us',
	'pt': 'Contacte-nos'
    },
    'on Solo': {
	'en': 'on Solo',
	'pt': 'na Solo'
    },
    'we develop': {
	'en': 'we develop',
	'pt': 'desenvolvemos soluções'
    },
    'digital solutions to': {
	'en': 'digital solutions to',
	'pt': 'digitais para simplificar'
    },
    'simplify processes and workflows.': {
	'en': 'simplify processes and workflows.',
	'pt': 'processos e fluxos de trabalho.'
    },
    // Process
    'Why<br/> develop<br/> custom<br/> software<br/> for your<br/> business.': {
	'en': 'Why<br/> develop<br/> custom<br/> software<br/> for your<br/> business.',
	'pt': 'Porque<br/> desenvolver<br/> software<br/> personalizado<br/> para o seu<br/> negócio.'
    },
    'Less errors': {
	'en': 'Less errors',
	'pt': 'Menos erros'
    },
    'Fewer problems': {
	'en': 'Fewer problems',
	'pt': 'Menos problemas'
    },
    'Less manual tasks': {
	'en': 'Less manual tasks',
	'pt': 'Menos tarefas manuais'
    },
    'Less time spent': {
	'en': 'Less time spent',
	'pt': 'Menos gasto de tempo'
    },
    'More engaged workers': {
	'en': 'More engaged workers',
	'pt': 'Colaboradores mais envolvidos'
    },
    'More speed and agility': {
	'en': 'More speed and agility',
	'pt': 'Maior agilidade e velocidade'
    },
    'Planning': {
    	'en': 'Planning',
    	'pt': 'Planeamento'
    },
    'Clearly define the problem to be solved': {
	'en': 'Clearly define the problem to be solved',
	'pt': 'Definir com clareza o problema a resolver'
    },
    'Map and document the processes, flows and systems involved': {
	'en': 'Map the processes, flows and systems',
	'pt': 'Mapear os processos, fluxos e sistemas'
    },
    'Define a plan and a clear vision of where you want to reach': {
	'en': 'Build a clear vision of the solution',
	'pt': 'Construir uma visão clara da solução'
    },
    'Determine goals to define success without ambiguity': {
	'en': 'Outline a plan and set the success goals',
	'pt': 'Delinear um plano e as metas de sucesso'
    },
    'Implementation': {
    	'en': 'Implementation',
    	'pt': 'Implementação'
    },
    'Monitoring': {
    	'en': 'Monitoring',
    	'pt': 'Acompanhamento'
    },
    'Focus on priorities': {
    	'en': 'Focus on priorities',
    	'pt': 'Manter o foco nas prioridades'
    },
    'Develop phased and robust': {
    	'en': 'Develop phased and robust',
    	'pt': 'Desenvolver faseadamente e com solidez'
    },
    'Follow the plan with flexibility': {
    	'en': 'Follow the plan with flexibility',
    	'pt': 'Seguir o plano com flexibilidade'
    },
    'Test with real users': {
    	'en': 'Test with real users',
    	'pt': 'Testar com utilizadores reais'
    },
    'Monitor system performance': {
    	'en': 'Monitor system performance',
    	'pt': 'Monitorizar o desempenho do sistema'
    },
    'Continuously introduce improvements': {
    	'en': 'Continuously introduce improvements',
    	'pt': 'Introduzir melhorias continuamente'
    },
    'Maintain open communication with users': {
    	'en': 'Maintain open communication with users',
    	'pt': 'Manter a comunicação com os utilizadores'
    },
    'Ensure system security': {
    	'en': 'Ensure system security',
    	'pt': 'Garantir a segurança do sistema'
    },
    'Next Project': {
	'en': 'Next Project',
	'pt': 'Próximo Projeto'
    },
    'Begin the <b>digital transformation</b> of your business.': {
	'en': 'Begin the <b>digital transformation</b> of your business.',
	'pt': 'Aposte na <b>transformação digital</b> da sua empresa.'
    },
    'Digitize processes and ensure greater speed and flexibility, with less waste.': {
	'en': 'Digitize your business processes and ensure greater<br/> <b>speed</b> and <b>flexibility</b>, with less waste.',
	'pt': 'Digitalize processos e garanta maior<br/> <b>rapidez</b> e <b>flexibilidade</b>, com menor desperdício.'
    },
    'What process are we going<br/> to digitize first?': {
	'en': 'What process are we going<br/> to digitize first?',
	'pt': 'Que processo vamos <br/> digitalizar primeiro?'
    },
    'Sourcing': {
	'en': 'Sourcing',
	'pt': 'Compras'
    },
    'Samples': {
	'en': 'Samples',
	'pt': 'Produção Amostras'
    },
    'Developments': {
	'en': 'Developments',
	'pt': 'Desenvolvimentos'
    },
    'Digital Asset Management': {
	'en': 'Digital Asset Management',
	'pt': 'Gestão Ativos Digitais'
    },
    'Stock Management': {
	'en': 'Stock Management',
	'pt': 'Gestão Stocks'
    },
    'Quality Control': {
	'en': 'Quality Control',
	'pt': 'Controlo Qualidade'
    },
    'Production Control': {
	'en': 'Production Control',
	'pt': 'Controlo Fluxo Produção'
    },
    'Sales Management': {
	'en': 'Sales Management',
	'pt': 'Gestão Vendas'
    },
    'Innovation Management': {
	'en': 'Innovation Management',
	'pt': 'Gestão Inovação'
    },
    'Process digitization enables faster, more flexible production with greater quality control and client satisfaction.': {
	'en': 'Process digitization enables faster, more flexible production with greater quality control and client satisfaction.',
	'pt': 'A digitalização dos processos potencia uma produção mais rápida e flexível com um maior controlo de qualidade e satisfação do cliente.'
    }
}

export default TranslationsData;
