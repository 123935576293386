import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import {GET_STATUS} from '../../services/Queries';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import styles from './Status.module.scss';
import classNames from 'classnames';
import Loading from '../Loading/Loading';

const Status = () => {

    const {data, loading} = useQuery(GET_STATUS);

    if(loading) {
        return <Loading/>;
    }

    const status = data.get_status;

    return (
        <Grid container justify="center" alignItems="center" className={styles.StatusWrapper}>
          <Card className={styles.Status}>
            <p data-cy="status" className={classNames({[styles.Up]: status === 'up'})}>
              API STATUS: {status.toUpperCase()}
            </p>
          </Card>
        </Grid>
    );
};

export default Status;
