import {Model} from './base/Model';
import Collection from './base/Collection';
import {decorate, computed} from 'mobx';
import Api from './base/Api';

export class User extends Model {
    urlRoot() {
        return "/users/";
    }

    get avatarFullPath() {
        return Api.getBaseURL() + '/static/media/' + this.get('avatar');
    }

}

decorate(User, {
    avatarFullPath: computed,
})

export class Users extends Collection {
    urlRoot() {
        return "/users/";
    }

    model() {
        return User
    }
}
