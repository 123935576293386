import gql from 'graphql-tag';

// LOCAL STATE

export const LOGOUT_USER = gql`
mutation logoutUser {
    logoutUser @client
}
`;

export const LOGIN_USER = gql`
mutation loginUser($token: String!, $user: User!) {
    loginUser(token: $token, user: $user) @client
}
`;
