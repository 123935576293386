import {Model} from './base/Model';
import Collection from "./base/Collection";

export class Contact extends Model {
    urlRoot() {
        return "/contacts/";
    }
}

export class Contacts extends Collection {
    urlRoot() {
        return "/contacts/";
    }

    model() {
        return Contact
    }
}
