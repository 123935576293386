import React, {useState} from 'react';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import styles from './NewsletterView.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from "yup";
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import {useMutation} from '@apollo/react-hooks';
import {CREATE_SUBSCRIPTION} from '../../services/Queries';
import Grid from '@material-ui/core/Grid';

const ThankYou = () => {
    return (
        <h3 data-cy="thankyou">
          Obrigado pela sua inscrição.
        </h3>
    );
};

const NewsletterForm = ({submit}) => {

    const subscribeSchema = Yup.object().shape({
        email: Yup.string()
            .email('email tem que ser válido')
            .required('email é um campo obrigatório'),
        name: Yup.string(),
    });

    return (
        <Formik
          initialValues={{
              name: '',
              email: ''
          }}
          validationSchema={subscribeSchema}
          onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
              submit(values).then((response, loading, error) => {
                  setSubmitting(false);
              });
          }}
        >
          {({isValid, errors, touched, dirty, submitForm, isSubmitting}) => (
              <Form>
                <Grid container direction="column">
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="email"
                    data-cy="email"
                    required
                    autoFocus
                    className={styles.FormField}
                  />
                  <Field
                    component={TextField}
                    name="name"
                    type="text"
                    label="nome"
                    data-cy="name"
                    className={styles.FormField}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isValid | !dirty}
                    onClick={submitForm}
                    data-cy="submit"
                    className={styles.FormField}
                  >
                    Submeter
                  </Button>
                </Grid>
              </Form>
          )}
        </Formik>
    );
};

const NewsletterView = () => {

    const [submitted, setSubmitted] = useState(false);
    const [createSubscription] = useMutation(CREATE_SUBSCRIPTION);

    const submit = values => {
        return createSubscription({variables: values}).then(() => setSubmitted(true));
    };

    return (
        <>
          <Menu background='dark' external />
          <div style={{height: '75px'}}/>
          <Grid container justify="center" className={styles.NewsletterView}>
            <Grid container direction="column" item xs={10} lg={5}>
              <h2>Subscreva a nossa Newsletter</h2>
              <h3>
                Esteja a par das nossas novidades e promoções e receba diretamente
                na sua caixa de correio informações úteis e práticas sobre
                <b> transformação digital, gestão de processos e performance operacional.</b>
              </h3>
              {submitted ? <ThankYou/> : <NewsletterForm submit={submit}/>}
              <p className={styles.Disclaimer}>
                <small>
                  Envio no máximo de 2 emails por mês.<br/>
                  Pode cancelar a sua inscrição a qualquer momento.
                </small>
              </p>
            </Grid>
          </Grid>
          <Footer/>
        </>
    );
};

export default NewsletterView;
