import {Model} from './base/Model';
import Collection from "./base/Collection";
import {decorate, computed} from 'mobx';
import Api from "./base/Api";

export class Project extends Model {
    urlRoot() {
        return "/projects/";
    }

    get logoFullPath() {
        return Api.getBaseURL() + '/static/media/' + this.get('logo');
    }

}

decorate(Project, {
    logoFullPath: computed
})


export class Projects extends Collection {
    urlRoot() {
        return "/projects/";
    }

    model() {
        return Project
    }
}
