import React from 'react';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import {Translator} from '../../services/TranslationsStore';

import styles from './languageSelector.module.scss';

const Link = props => (
    <a href="#selector" onClick={() => Translator.changeLang(props.lang)} className={props.classes}>
      {props.lang}
    </a>
);

const DesktopOption = props => {
    return <Link {...props} />;
};

const MobileOption = props => {
    return (
        <div className={styles.MobileOption}>
          <Link {...props}/>
        </div>
    );
};

const Option = props => {
    const classes = classNames({[styles.Bold]: Translator.lang === props.lang});
    return props.variant === 'desktop' ? <DesktopOption {...props} classes={classes} /> : <MobileOption {...props} classes={classes}/>;
};

const LanguageSelector = props => {
    const {languages} = Translator;
    const variant = props.width === 'xs'? 'mobile' : 'desktop';
    return (
        <div className={styles.LanguageSelector}>
          {languages.map(language => <Option key={language.code} lang={language.code} variant={variant} />)}
        </div>
    );
};

export default withWidth()(LanguageSelector);
