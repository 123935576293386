import React, {Component} from 'react';
import {observer} from 'mobx-react';
import BlogHome from '../blogHome/blogHome.js';
import BlogContent from '../blogContent/blogContent.js';

class Blog extends Component {

    render() {
        const slug = this.props.match.params.slug;

        if(slug)  {
            return <BlogContent slug={slug}/>;
        }

        return <BlogHome/>;
    }
}

export default observer(Blog);
